import {
  ClassicEditor,
  Context,
  Bold,
  Essentials,
  Italic,
  Paragraph,
  ContextWatchdog,
  FontSize,
  FontFamily,
  FontColor,
  FontBackgroundColor,
  ListEditing,
  Link,
  Superscript,
  Subscript,
  Alignment,
  InlineEditor,
  DecoupledEditor,
  Image,
  ImageCaption,
  ImageInsert,
  ImageResize,
  ImageStyle,
  AutoImage,
  ImageUpload
} from "ckeditor5";
import { CKEditor, CKEditorContext } from "@ckeditor/ckeditor5-react";
// import {
//   Image,
//   ImageCaption,
//   ImageInsert,
//   ImageResize,
//   ImageStyle,
//   AutoImage,
//   ImageUpload
// } from "@ckeditor/ckeditor5-image";
import "ckeditor5/ckeditor5.css";

function CKEditorApp({ value, onChange }: CKEditorAppProps) {
  return (
    <CKEditorContext context={Context} contextWatchdog={ContextWatchdog}>
      <CKEditor
        editor={ClassicEditor}
        config={{
          // image: {
          //   toolbar: [
          //     "toggleImageCaption",
          //     "imageTextAlternative",
          //     "ckboxImageEdit",
          //   ],
          // },

          plugins: [
            Essentials,
            Bold,
            Italic,
            Superscript,
            Subscript,
            Alignment,
            Paragraph,
            FontSize,
            FontFamily,
            FontColor,
            FontBackgroundColor,
            ListEditing,
            Link,

            Image,
            ImageUpload,
            AutoImage,
            ImageCaption,
            ImageInsert,
            ImageResize,
            ImageStyle,
          ],
          toolbar: [
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "superscript",
            "subscript",
            "alignment",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "link",
            "insertImage",
            "autoImage",
            "insertImageViaUrl",
            "uploadImage",
            "resizeImage",
            "styleImage",
            "captionImage",
            "toggleImageCaption",
            "imageTextAlternative",
            "ckboxImageEdit",
          ],
          image: {
            
            resizeOptions: [
              {
                name: "resizeImage:original",
                label: "Default image width",
                value: null,
              },
              {
                name: "resizeImage:50",
                label: "50% page width",
                value: "50",
              },
              {
                name: "resizeImage:75",
                label: "75% page width",
                value: "75",
              },
            ],
            toolbar: [
              "imageTextAlternative",
              "toggleImageCaption",
              "|",
              "imageStyle:inline",
              "imageStyle:wrapText",
              "imageStyle:breakText",
              "|",
              "resizeImage",
              'toggleImageCaption', 'imageTextAlternative', 'ckboxImageEdit'
            ],
          },
          //   image: {
          //     toolbar: [ 'toggleImageCaption', 'imageTextAlternative', 'ckboxImageEdit' ]
          // }
        }}
        data={value}
        onReady={(editor) => {
          // console.log("editor",editor);
          //   const editableElement = editor.editing.view.getDomRoot();
          //   const observer = new ResizeObserver( editableElement, entry => {
          //       console.log( 'The editable element has been resized in DOM.' );
          //       console.log( entry.target ); // -> editableElement
          //       console.log( entry.contentRect.width ); // -> e.g. '423px'
          //   } );
          // console.log("Editor 1 is ready to use!", editor);
        }}
        onChange={onChange}
      />
    </CKEditorContext>
  );
}

export default CKEditorApp;
