import "./index.css";
import {
  CopyOutlined,
  DeleteOutlined,
  PlusOutlined,
  CaretRightOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import AddAnswers from "../answer/AddAnswers";
import CKEditorApp from "../ckeditor/CKEditor";
import PopConfirmApp from "../button/PopConfirmApp";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Card, Col, Collapse, Form, Row, Select, Tooltip } from "antd";
import { column_type, question_type } from "./index.config";

const AddQuesitions: React.FC<IAddQuesitionsProps> = (props) => {
  const { form, setDisabledSave } = props;
  const [answerType, setAnswerType] = useState<"option" | "input">("option");

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const questions = form.getFieldValue("questions");
    const [reorderedItem] = questions.splice(result.source.index, 1);
    questions.splice(result.destination.index, 0, reorderedItem);

    form.setFieldsValue({
      questions: questions,
    });
  };

  return (
    <Form.List name="questions">
      {(fields, { add, remove }) => (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="questions">
            {(provided: any) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map(({ key, name, ...restField }, i) => {
                  let currentQuestion = form.getFieldValue(["questions"])[key];
                  let entries: any[] =
                    currentQuestion && Object.entries(currentQuestion);
                  let validations: boolean[] = Array(
                    form.getFieldValue(["questions"])?.length ?? 0
                  ).fill(false);
                  if (entries) {
                    for (let i = 0; i < entries?.length; i++) {
                      const [q_key, q_value] = entries[i]; // current question [key, value]
                      const isRights =
                        q_key == "answers" &&
                        q_value?.filter(
                          (answer: any) => answer?.isRight == true
                        )?.length == 1 &&
                        q_value.length >=
                          (currentQuestion?.type == "option" ? 2 : 1);
                      if (
                        (q_key != "answers" &&
                          q_key != "title" &&
                          q_key != "explanation" &&
                          q_value?.length === 0) ||
                        (q_key == "answers" && isRights == false)
                      ) {
                        validations[key] = true;
                      }
                    }
                  }

                  // set Disability to Save button
                  const questionsCount =
                    form.getFieldValue(["questions"])?.length ?? 0;
                  const prevValidation = JSON.parse(
                    sessionStorage.getItem("questionsValidate") ?? "[]"
                  );
                  if (
                    prevValidation.length != 0 &&
                    questionsCount != prevValidation.length
                  ) {
                    sessionStorage.setItem(
                      "questionsValidate",
                      JSON.stringify([...prevValidation, { key, validations }])
                    );
                    // console.log("validations", validations);

                    setDisabledSave(
                      [...prevValidation, { key, validations }]
                        .map((x: any) => x?.validations)
                        .flat(1)
                        .includes(true)
                    );
                  } else {
                    sessionStorage.setItem(
                      "questionsValidate",
                      JSON.stringify([{ key, validations }])
                    );
                    // console.log("validations", validations);
                    const value: boolean = [{ key, validations }]
                      .map((x: any) => x?.validations)
                      .flat(1)
                      .includes(true);
                    setDisabledSave(value);
                  }
                  if (currentQuestion == undefined) {
                    setDisabledSave(true);
                  }

                  return (
                    <Draggable key={key} draggableId={key.toString()} index={i}>
                      {(provided: any) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Collapse
                            key={"collapse-question-" + key}
                            style={{
                              borderWidth: "2px",
                              borderColor:
                                validations[key] == true ||
                                currentQuestion == undefined
                                  ? "red"
                                  : "green",
                            }}
                            items={[
                              {
                                key: key,
                                label: (
                                  <div className="d-flex-btw">
                                    <h3>Question #{i + 1}</h3>
                                    <div className="d-flex">
                                      <Tooltip
                                        title="Duplicate the question"
                                        className="mr-10p"
                                      >
                                        <Button
                                          type="default"
                                          icon={<CopyOutlined />}
                                          onClick={() => {
                                            const allQuestions =
                                              form.getFieldsValue()?.questions;
                                            const updatedQuestions = [
                                              ...allQuestions.slice(0, key + 1),
                                              {
                                                ...allQuestions[key],
                                                _id: `null-${key + 1}`, // <= DUBLICATED QUESTION
                                              },
                                              ...allQuestions.slice(key + 1),
                                            ];
                                            form.setFieldsValue({
                                              ...form.getFieldsValue(),
                                              questions: updatedQuestions,
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                      <PopConfirmApp
                                        tooltip="Delete question"
                                        confirm={() => remove(name)}
                                        children={
                                          <Button
                                            icon={<DeleteOutlined />}
                                            danger
                                          ></Button>
                                        }
                                      />
                                    </div>
                                  </div>
                                ),
                                children: (
                                  <Card
                                    key={key}
                                    className="practice-question-card"
                                  >
                                    <Row gutter={[16, 16]}>
                                      <Col {...{ md: 12, lg: 12 }}>
                                        <Form.Item
                                          {...restField}
                                          label="Type"
                                          name={[name, "type"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please select a type!",
                                            },
                                          ]}
                                          initialValue={"option"}
                                        >
                                          <Select
                                            size="large"
                                            style={{ width: "100%" }}
                                            onChange={(val) => {
                                              setAnswerType(val);
                                              form.setFieldsValue(
                                                form.getFieldsValue()
                                              );
                                            }}
                                            options={question_type}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col {...{ md: 12, lg: 12 }}>
                                        <Form.Item
                                          {...restField}
                                          label="Column"
                                          name={[name, "column"]}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please select a column!",
                                            },
                                          ]}
                                          initialValue={"single"}
                                        >
                                          <Select
                                            size="large"
                                            style={{ width: "100%" }}
                                            options={column_type}
                                            onChange={(val) => {
                                              form.setFieldsValue(
                                                form.getFieldsValue()
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col {...{ md: 24, lg: 24 }}>
                                        <Form.Item
                                          {...restField}
                                          label="Content"
                                          name={[name, "content"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please enter content!",
                                            },
                                          ]}
                                          initialValue={""}
                                        >
                                          <CKEditorApp
                                            value={form.getFieldValue([
                                              "questions",
                                              name,
                                              "content",
                                            ])}
                                            onChange={(
                                              event: any,
                                              editor: any
                                            ) => {
                                              const data = editor.getData();
                                              form.setFieldsValue({
                                                questions: [
                                                  ...form
                                                    .getFieldValue("questions")
                                                    .slice(0, name),
                                                  {
                                                    ...form.getFieldValue(
                                                      "questions"
                                                    )[name],
                                                    content: data,
                                                  },
                                                  ...form
                                                    .getFieldValue("questions")
                                                    .slice(name + 1),
                                                ],
                                              });
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col {...{ md: 24, lg: 24 }}>
                                        <Form.Item
                                          {...restField}
                                          label="Title"
                                          name={[name, "title"]}
                                          rules={[
                                            {
                                              required: false,
                                              message: "Please enter a title!",
                                            },
                                          ]}
                                          initialValue={""}
                                        >
                                          <CKEditorApp
                                            value={form.getFieldValue([
                                              "questions",
                                              name,
                                              "title",
                                            ])}
                                            onChange={(
                                              event: any,
                                              editor: any
                                            ) => {
                                              const data = editor.getData();
                                              form.setFieldsValue({
                                                questions: [
                                                  ...form
                                                    .getFieldValue("questions")
                                                    .slice(0, name),
                                                  {
                                                    ...form.getFieldValue(
                                                      "questions"
                                                    )[name],
                                                    title: data,
                                                  },
                                                  ...form
                                                    .getFieldValue("questions")
                                                    .slice(name + 1),
                                                ],
                                              });
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col {...{ md: 24, lg: 24 }}>
                                        <Form.Item
                                          {...restField}
                                          label="Explanation"
                                          name={[name, "explanation"]}
                                          rules={[
                                            {
                                              required: false,
                                              message: "Please enter a title!",
                                            },
                                          ]}
                                          initialValue={""}
                                        >
                                          <CKEditorApp
                                            value={form.getFieldValue([
                                              "questions",
                                              name,
                                              "explanation",
                                            ])}
                                            onChange={(
                                              event: any,
                                              editor: any
                                            ) => {
                                              const data = editor.getData();
                                              form.setFieldsValue({
                                                questions: [
                                                  ...form
                                                    .getFieldValue("questions")
                                                    .slice(0, name),
                                                  {
                                                    ...form.getFieldValue(
                                                      "questions"
                                                    )[name],
                                                    explanation: data,
                                                  },
                                                  ...form
                                                    .getFieldValue("questions")
                                                    .slice(name + 1),
                                                ],
                                              });
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col {...{ md: 24, lg: 24 }}>
                                        <AddAnswers
                                          form={form}
                                          name={name}
                                          answerType={answerType}
                                        />
                                      </Col>
                                    </Row>
                                  </Card>
                                ),
                              },
                            ]}
                            className="mt-20p"
                            expandIcon={({ isActive }) => {
                              return (
                                <>
                                  <HolderOutlined
                                    className={isActive ? "d-none" : ""}
                                  />
                                  <CaretRightOutlined
                                    rotate={isActive ? 90 : 0}
                                  />
                                </>
                              );
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => add()}
              className="mt-5v"
              icon={<PlusOutlined />}
              block
            >
              Add Question
            </Button>
          </Form.Item>
        </DragDropContext>
      )}
    </Form.List>
  );
};

export default AddQuesitions;
