import {Card, Col, Row } from "antd";
import { PracticeCardProps } from ".";

export default function PracticeCard(props:PracticeCardProps) {
  const {title, title_icon, right_buttons, main} = props
  return (
    <Card
      title={
        <div className="d-flex-btw">
          <h1>
            {title_icon}
            {title}
          </h1>
          <div>
            {right_buttons}           
          </div>
        </div>
      }
      style={{ border: "none" }}
    >
      <Row gutter={[32, 32]} className="mt-25p">
        <Col md={7} lg={7}>{main.left_side}</Col>
        <Col md={16} lg={16}>{main.right_side}</Col>
      </Row>
    </Card>
  );
}
