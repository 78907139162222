import axios, { AxiosRequestConfig } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('auth'); // Retrieve the token from sessionStorage
    if (token && config.headers) {
      (config.headers as any)['Authorization'] = `Bearer ${token}`; // Set the token in the Authorization header
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export default axiosInstance;
