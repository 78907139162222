import "./index.css"
import { Button, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { setLoaders, setLogged } from "../../../redux-toolkit";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const { Header } = Layout;
interface IProps {
  collapsed: boolean;
  HandleCollapse: React.ReactEventHandler;
}
export default function Navbar(props: IProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Header className="header d-flex-btw">
        <div className="d-flex-btw">
          <Button
            type="text"
            style={{ width: 64, height: 64 }}
            icon={
              props.collapsed ? (
                <MenuUnfoldOutlined className="menu-icon" />
              ) : (
                <MenuFoldOutlined className="menu-icon" />
              )
            }
            onClick={props.HandleCollapse}
          ></Button>
          <h3 style={{ marginLeft: 15 }}>Admin panel</h3>
        </div>
        <Button
          type="text"
          onClick={() => {
            dispatch(setLoaders({ signLoad: false }));
            dispatch(setLogged(false));
            navigate("/");
            sessionStorage.clear();
          }}
          style={{ width: 64, height: 64 }}
          icon={<LogoutOutlined className="menu-icon" />}
        />
      </Header>
    </>
  );
}
