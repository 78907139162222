import { Layout, Menu, MenuProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { LocalizationData } from "../../../utils/helpers";

const { Sider } = Layout;

interface IProps {
  collapsed: boolean;
}

export default function Sidebar(props: IProps) {
  const navigate = useNavigate();
  const menuItems =
    LocalizationData.components.mainLayout.sidebar.menuList(navigate);

  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={props.collapsed}
        theme="light"
      >
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
          className="left-menu"
          items={menuItems}
        />
      </Sider>
    </>
  );
}
