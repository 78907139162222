import { message } from "antd";
import { useEffect } from "react";
import { useAppSelector } from "../app/hooks";

const useMessage = () => {
  const { notification } = useAppSelector((state) => state.global);
  const [messageApi, contextHolder] = message.useMessage();

  // const showMessage = (
  //   type: "success" | "error" | "warning",
  //   content: string
  // ) => {
  //   messageApi.open({
  //     type,
  //     content,
  //   });
  // };

  useEffect(() => {
    if(typeof notification.type === "string"){
      messageApi.open(notification);
    }
  }, [notification]);
  return {
    contextHolder,
  };
};

export default useMessage;
