import { FolderAddFilled, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import useMessage from "../../../../hooks/useMassage";
import {
  GET_PRACTICE,
  UPDATE_PRACTICE,
} from "../../../../redux-toolkit/saga/types";
import dayjs from "dayjs";
import TransferApp from "../../../../components/list/transfer-app/TransferApp";
import PracticeCard from "../../../../components/layout/card/PracticeCard";

interface IProps {
  sectionsTabBar: React.ReactNode;
  clone: boolean;
}

function MainDetails(props: IProps) {
  const { sectionsTabBar, clone } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [forGroups, setForGroups] = useState<boolean>(false);
  const { updatedPractice, loaders } = useAppSelector((state) => state.global);
  const { contextHolder } = useMessage();

  const handleSavePractice = (val: any) => {
    form
      .validateFields()
      .then((values) => {
        if (values.startsAt) {
          values.startsAt = dayjs(values.startsAt).format("YYYY-MM-DD HH:mm");
        }
        if (values.couldStartsAt) {
          values.couldStartsAt = dayjs(values.couldStartsAt).format(
            "YYYY-MM-DD HH:mm"
          );
        }
        const prId = window.location.href.split("?id=").pop();

        dispatch({
          type: UPDATE_PRACTICE,
          payload: { id: prId, body: values },
        });
        console.log("new practice vals:", values);
        // form.resetFields();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const onOk = (value: DatePickerProps["value"]) => {
    console.log("onOk: ", value);
  };
  useEffect(() => {
    const prId = window.location.href.split("?id=").pop();
    if (!(updatedPractice && updatedPractice?._id === prId))
      dispatch({
        type: GET_PRACTICE,
        payload: { id: prId },
      });
  }, [dispatch]);
  useEffect(() => {
    if (updatedPractice) {
      form.setFieldsValue({
        ...updatedPractice,
        couldStartsAt: dayjs(updatedPractice?.couldStartsAt),
        startsAt: dayjs(updatedPractice?.startsAt),
      });
    }
  }, [updatedPractice]);
  return (
    <>
      {contextHolder}
      <Spin spinning={loaders.practiceLoad}>
        <Form
          name="practice-details-update"
          layout="vertical"
          form={form}
          autoComplete="off"
          onFinish={handleSavePractice}
          className="practice-details-form"
          initialValues={{
            name: "",
            startsAt: null,
            isActive: false,
            isForGroup: false,
            couldReview: false,
            couldStartsAt: null,
          }}
        >
          <PracticeCard
            title={updatedPractice?.name ?? "Practice Update"}
            title_icon={<FolderAddFilled className="title-folder-icon" />}
            right_buttons={
              <Button
                icon={<SaveOutlined />}
                type="primary"
                htmlType="submit"
                className="dyn-submit-btn"
                style={{ width: 150 }}
                disabled={clone}
              >
                Save
              </Button>
            }
            main={{
              left_side: sectionsTabBar,
              right_side: (
                <>
                  {!clone && (
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the field!",
                            },
                          ]}
                        >
                          <Input size="large" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Start Date"
                          name="startsAt"
                          rules={[
                            {
                              required: true,
                              type: "object" as const,
                              message: "Please enter the field!",
                            },
                          ]}
                        >
                          <DatePicker
                            format="YYYY-MM-DD HH:mm"
                            showTime
                            showSecond={false}
                            size="large"
                            style={{ width: "100%" }}
                            onChange={(date, dateString) => {
                              console.log(date, dateString);
                            }}
                            onOk={onOk}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Could Start Date"
                          name="couldStartsAt"
                          rules={[
                            {
                              required: true,
                              type: "object" as const,
                              message: "Please enter the field!",
                            },
                          ]}
                        >
                          <DatePicker
                            format="YYYY-MM-DD HH:mm"
                            showTime
                            showSecond={false}
                            size="large"
                            style={{ width: "100%" }}
                            onChange={(date, dateString) => {
                              console.log(date, dateString);
                            }}
                            onOk={onOk}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Active"
                          name="isActive"
                          valuePropName="checked"
                        >
                          <Switch defaultChecked={false} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Could Review"
                          name="couldReview"
                          valuePropName="checked"
                        >
                          <Switch defaultChecked={false} />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="For Group"
                          name="isForGroup"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={false}
                            onChange={(checked) => setForGroups(checked)}
                          />
                        </Form.Item>
                      </Col>
                      {forGroups && (
                        <Col span={12}>
                          <Form.Item
                            label="Groups"
                            name="groupIds"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the field!",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: "100%" }}
                              placeholder="Please select"
                              options={[
                                { label: "101A", value: 101 },
                                { label: "101B", value: 201 },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}
                  {clone && <TransferApp />}
                </>
              ),
            }}
          />
        </Form>
      </Spin>
    </>
  );
}

export default memo(MainDetails);
