// src/services/BaseService.ts

import axiosInstance from "./axiosInstance";


export interface IService<T, K> {
  create(payload: T, url?: string): Promise<K>;
  getById(id: string, url?: string): Promise<K>;
  get(params: any, url?: string): Promise<K>;
  post(payload: T, url?: string): Promise<K>;
  update(id: string, payload: T): Promise<K>;
  delete(id: string): Promise<K>;
}

export class BaseService<T, K> implements IService<T, K> {
  protected endpoint: string;
  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }
  async create(payload: T, url?: string): Promise<K> {
    const response = await axiosInstance.post<K>(url ?? this.endpoint, payload);
    return response.data;
  }
  async get(params: any, url?: string): Promise<K> {
    const response = await axiosInstance.get<K>(url ?? this.endpoint, {
      params,
    });
    return response.data;
  }
  async getById(id: string, url?: string): Promise<K> {
    const response = await axiosInstance.get<K>(url ? url+`/${id}` : this.endpoint+`/${id}`);
    return response.data;
  }
  async post(payload: T, url?: string): Promise<K> {
    const response = await axiosInstance.post<K>(url ?? this.endpoint, payload);
    return response.data;
  }
  async update(id: string, payload: T, url?: string): Promise<K> {
    const response = await axiosInstance.put<K>(
      url ? url + `/${id}` : this.endpoint + `/${id}`,
      payload
    );
    return response.data;
  }
  async delete(id: string, url?: string): Promise<K> {
    const response = await axiosInstance.delete<K>(
      url ? url + `/${id}` : this.endpoint + `/${id}`
    );

    return response.data;
  }
}
