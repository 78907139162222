import "./index.css";
import {
  DownloadOutlined,
  FolderAddFilled,
  SaveOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Divider, Form, Input, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import UploadFileParser from "../../../components/converters/UploadFileParser";
import AddQuesitions from "../../../components/quesition/AddQuesitions";
import useMessage from "../../../hooks/useMassage";
import { setSectionsById } from "../../../redux-toolkit";
import {
  CREATE_SECTION,
  CURRENT_SECTIONS,
  UPDATE_SECTION,
} from "../../../redux-toolkit/saga/types";
import { Fields } from "./index.config";
import MainDetails from "./main-details/MainDetails";
import SectionsTab from "./sections-tab/SectionsTab";
import PracticeCard from "../../../components/layout/card/PracticeCard";

const PracticeDetails: React.FC = React.memo(() => {
  // window.scrollTo(0, 0);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { contextHolder } = useMessage();
  const [changed, setChanged] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const [dragging, setDragging] = useState<string | null>(null);
  const [currentSections, setCurrentSections] = useState<any[]>([]);
  const [practiceUpdate, setPracticeUpdate] = useState<{
    details: boolean;
    clone: boolean;
  }>({ details: true, clone: false });
  const [selectedSectionId, setSelectedSectionId] = useState<string | null>(
    null
  );

  const { currentSectionsById, loaders } = useAppSelector(
    (state) => state.global
  );

  useEffect(() => {
    dispatch({
      type: CURRENT_SECTIONS,
      payload: { id: window.location.href.split("?id=").pop() },
    });
  }, [dispatch]);

  // form handling functions
  const handleTabClick = (event: any) => {
    sessionStorage.removeItem("questionsValidate");
    const key = event.target.id;
    setSelectedSectionId(key);
    setPracticeUpdate({ clone: false, details: false });
    const _id = key.includes("null") ? key : key.split("-").pop();
    form.setFieldsValue(currentSectionsById.find((s) => s._id === _id));
  };
  const handleSave = (val: any) => {
    form
      .validateFields()
      .then((values) => {
        const id = window.location.href.split("?id=").pop();
        const payload = {
          ...values,
          questions: values.questions.map((item: any) => ({
            ...item,
            isOpen: false,
          })),
          practiceId: id,
        };

        if (!values._id.includes("null")) {
          dispatch({
            type: UPDATE_SECTION,
            payload: { id: values._id, body: payload },
          });
        } else {
          dispatch({ type: CREATE_SECTION, payload });
          form.setFieldsValue(values);
        }

        setChanged(!changed);
      })
      .catch((error) => console.log("Error", error));
  };
  const handleAddSection = () => {
    setDisabledSave(true);
    const newSection = {
      _id: ` ${currentSectionsById.length}-${null}`,
      name: `New Section-${currentSectionsById.length}`,
      content: "",
      duration: 0,
      break: 0,
      type: "math",
      shuffle: false,
      shuffleAsnwers: false,
      questions: [],
    };
    setCurrentSections([...currentSections, newSection]);
    dispatch(setSectionsById([...currentSectionsById, newSection]));
  };

  const sectionFields = Fields({ form }); // section fields configs
  return (
    <>
      {contextHolder}
      <Spin spinning={loaders.practiceLoad}>
        {!practiceUpdate.details && (
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={handleSave}
            name="practice-details"
            className="practice-details-form"
          >            
            <PracticeCard
              title="Practice Details"
              title_icon={<FolderAddFilled className="title-folder-icon" />}
              right_buttons={
                <Button
                  icon={<SaveOutlined />}
                  type="primary"
                  htmlType="submit"
                  className="dyn-submit-btn"
                  style={{ width: 150 }}
                  disabled={
                    (practiceUpdate.details && practiceUpdate.clone) ||
                    disabledSave
                  }
                >
                  Save
                </Button>
              }
              main={{
                left_side: (
                  <SectionsTab
                    practiceUpdate={practiceUpdate}
                    handleTabClick={handleTabClick}
                    handleAddSection={handleAddSection}
                    setPracticeUpdate={setPracticeUpdate}
                    currentSections={currentSectionsById}
                    selectedSectionId={selectedSectionId}
                  />
                ),
                right_side: (
                  <Row gutter={[16, 16]}>
                    <Form.Item
                      name="_id"
                      rules={[
                        {
                          required: false,
                          type: "string",
                          message: "Please enter the field!",
                        },
                      ]}
                      hidden
                    >
                      <Input hidden type="text" />
                    </Form.Item>
                    {sectionFields &&
                      sectionFields?.map((field, index) => (
                        <Col
                          key={index}
                          md={field?.name === "type" ? 24 : 12}
                          lg={field?.name === "type" ? 24 : 12}
                        >
                          <Form.Item
                            name={field.name}
                            label={field.label}
                            rules={[
                              {
                                required: field.required,
                                type: field.type as any,
                                message: "Please enter the field!",
                              },
                            ]}
                            valuePropName={field.valuePropName}
                            initialValue={field.initialValue}
                          >
                            {field.component}
                          </Form.Item>
                        </Col>
                      ))}
                    <Col md={24} lg={24}>
                      <AddQuesitions
                        form={form}
                        setDisabledSave={setDisabledSave}
                      />
                      <Divider orientation="left" className="mt-5v">
                        Import questions
                      </Divider>
                      <Row gutter={[16, 16]}>
                        <Col sm={24} md={12} lg={12}>
                          <UploadFileParser
                            HandleAddData={(data: any) => {
                              console.log("handle imported", data);
                              for (let q of data) {
                                const currentSect = form.getFieldsValue();
                                form.setFieldsValue({
                                  ...currentSect,
                                  questions: [
                                    ...currentSect.questions,
                                    {
                                      _id: `null-${currentSect.questions.length}`,
                                      content: `${q.content}`,
                                      title: `${q.title}`,
                                      explanation: `${q.explanation}`,
                                      type: q.type,
                                      column: q.column,
                                      answers: Object.entries(q)
                                        .filter(
                                          (x: any) =>
                                            x[0].includes("option") &&
                                            x[1].length > 0
                                        )
                                        ?.map((answer: any) => ({
                                          isRight: answer[0].includes(
                                            q.correct
                                          ),
                                          content: `<p>${answer[1]}</p>`,
                                        })),
                                    },
                                  ],
                                });
                              }
                            }}
                          />
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                          <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            size="large"
                            className="full-width"
                          >
                            Download example
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ),
              }}
            />
          </Form>
        )}
        {practiceUpdate.details && (
          <MainDetails
            clone={practiceUpdate.clone}
            sectionsTabBar={
              <SectionsTab
                practiceUpdate={practiceUpdate}
                handleTabClick={handleTabClick}
                handleAddSection={handleAddSection}
                setPracticeUpdate={setPracticeUpdate}
                currentSections={currentSectionsById}
                selectedSectionId={selectedSectionId}
              />
            }
          />
        )}
      </Spin>
    </>
  );
});

export default PracticeDetails;
