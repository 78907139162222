import { ILogin, IUser, IUserParams, IUserResponse } from ".";
import { BaseService } from "../baseService";

class UserService extends BaseService<any, IUserResponse> {
  constructor() {
    super("/users");
  }

  async login(data: ILogin) {
    const response = await this.create(data, "/login");
    return response;
  }
  async getUsers(data: IUserParams) {
    const response = await this.get(data);
    return response;
  }
  async addUser(data: IUser) {
    const response = await this.post(data);
    return response;
  }
  async updateUser(id: string, data: IUser) {
    const response = await this.update(id, data);
    return response;
  }
  async deleteUser(id: string) {
    const response = await this.delete(id);
    return response;
  }
}

export default new UserService();
