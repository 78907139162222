import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import global from '../redux-toolkit/index'
import createSagaMiddleware from 'redux-saga'
import mySaga from '../redux-toolkit/saga/sagas';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware]
export const store = configureStore({
  reducer: {
    global,
    // reducerName: sliceName,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
});
sagaMiddleware.run(mySaga)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
