import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LoadersState {
  signLoad: boolean;
  usersLoad: boolean;
  practiceLoad: boolean;
  transferLoad: boolean;
  [key: string]: boolean; // Allow for additional dynamic keys
}
export interface IGlobalStates {
  users: any[];
  newPractice: any;
  updatedPractice: any;
  currentSectionsById: any[];
  transferLeftSections: any[];
  transferSelectedPracticeId: string;
  anySectionsById: any[];
  importedQuestions: any[];
  createSection: any;
  updateSection: any;
  sections: any[];
  practices: any[];
  practicesWithSections: any[];
  practicesWithSectionsDublicate: any[];
  notification: {
    type: "success" | "error" | "warning" | undefined;
    content: string;
  };
  breadCrumbs: string[];
  currentStep: number;
  disableSave: boolean;
  questionValidate: boolean;
  isLogged: boolean;
  loaders: LoadersState;
  auth: string | null;
}
const initialState: IGlobalStates = {
  users: [],
  newPractice: null,
  updatedPractice: null,
  currentSectionsById: [{ name: "New Section#", _id: "123" }],
  anySectionsById: [],
  transferLeftSections: [],
  transferSelectedPracticeId: "",
  importedQuestions: [],
  sections: [],
  createSection: null,
  updateSection: null,
  practices: [],
  practicesWithSections: [],
  practicesWithSectionsDublicate: [],
  breadCrumbs: ["home"],
  notification: {
    type: undefined,
    content: "",
  },
  currentStep: 0,
  isLogged: false,
  disableSave: false,
  questionValidate:false ,
  loaders: {
    signLoad: false,
    usersLoad: false,
    practiceLoad: false,
    transferLoad: false,
  },
  auth: sessionStorage.getItem("auth") ?? null,
};

const global = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    setCrumbsSlice: (state) => {
      state.breadCrumbs = [
        "home",
        ...window.location.pathname.split("/").filter((x) => x != ""),
      ];
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setLogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setLoaders: (state, action: PayloadAction<Partial<LoadersState>>) => {
      for (const key in action.payload) {
        if (state.loaders.hasOwnProperty(key)) {
          state.loaders[key] = action.payload[key]!;
        }
      }
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setPractices: (state, action) => {
      state.practices = action.payload;
    },
    setPractice: (state, action) => {
      state.updatedPractice = action.payload;
    },
    setImportedQuestions: (state, action) => {
      state.importedQuestions = action.payload;
    },
    setPracticeWithSections: (state, action) => {
      state.practicesWithSections = action.payload;
    },
    setTransferLeftSections: (state, action) => {
      state.transferLeftSections = action.payload;
    },
    setTransferSelectedPracticeId: (state, action) => {
      state.transferSelectedPracticeId = action.payload;
    },
    setPracticeWithSectionsDublicate: (state, action) => {
      state.practicesWithSectionsDublicate = action.payload;
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setDisableSave: (state, action) => {
      state.disableSave = action.payload;
    },
    setValidateQuestion: (state, action) => {
      state.questionValidate = action.payload;
    },
    setSection: (state, action) => {
      state.createSection = action.payload;
      state.notification = {
        type: "success",
        content: "Muvafaqqiyatli amalga oshirildi!",
      };
    },
    setUpdateSection: (state, action) => {
      state.updateSection = action.payload;
      state.notification = {
        type: "success",
        content: "Muvafaqqiyatli amalga oshirildi!",
      };
    },
    setClonned: (state, action) => {
      state.notification = {
        type: "success",
        content: "Muvafaqqiyatli amalga oshirildi!",
      };
    },
    setSectionsById: (state, action) => {
      state.currentSectionsById = action.payload;
      state.transferLeftSections = action.payload
    },
    setAnySectionsById: (state, action) => {
      state.anySectionsById = action.payload;
    },
    setNewPractice: (state, action) => {
      state.newPractice = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setNewUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    setNotification: (state, action) => {
      if (action.payload.type === "success") {
        state.notification = {
          type: "success",
          content: "Muvafaqqiyatli amalga oshirildi!",
        };
      } else {
        state.notification = action.payload;
      }
    },
  },
});

export const {
  setAuth,
  setUsers,
  setLogged,
  setLoaders,
  setNewUser,
  setSection,
  setClonned,
  setSections,
  setPractice,
  setPractices,
  setDisableSave,
  setNewPractice,
  setCrumbsSlice,
  setCurrentStep,
  setSectionsById,
  setNotification,
  setUpdateSection,
  setAnySectionsById,
  setValidateQuestion,
  setImportedQuestions,
  setPracticeWithSections,
  setTransferLeftSections,
  setTransferSelectedPracticeId,
  setPracticeWithSectionsDublicate,
} = global.actions;
export default global.reducer;
