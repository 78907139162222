import "./index.css";
import { IAddAnswersProps } from ".";
import CKEditorApp from "../ckeditor/CKEditor";
import { default_answer } from "./index.config";
import { useAppDispatch } from "../../app/hooks";
import { setDisableSave } from "../../redux-toolkit";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Switch, Tooltip } from "antd";

function AddAnswers(props: IAddAnswersProps) {
  const dispatch = useAppDispatch();
  const { form, name, answerType } = props;

  return (
    <Form.List name={[name, "answers"]} initialValue={[default_answer]}>
      {(answerFields, { add: addAnswer, remove: removeAnswer }) => (
        <>
          {answerFields.map(
            ({ key: answerKey, name: answerName, ...answerRestField }, i) => (
              <Card
                key={answerKey}
                className="practice-answers-card answers-part mt-15p"
                title={
                  <div className="d-flex-btw">
                    <h1>Answer #{i + 1}</h1>
                    <Tooltip title="Delete answer">
                      <Button
                        type="default"
                        icon={<DeleteOutlined />}
                        onClick={() => removeAnswer(answerName)}
                        danger
                      />
                    </Tooltip>
                  </div>
                }
              >
                <Row gutter={[16, 16]}>
                  {answerType && answerType === "input" ? (
                    <Col {...{ md: 24, lg: 24 }}>
                      <Form.Item
                        {...answerRestField}
                        label="Content"
                        name={[answerName, "content"]}
                      >
                        <Input type="text" size="large" />
                      </Form.Item>
                    </Col>
                  ) : (
                    <>
                      <Col {...{ md: 24, lg: 24 }}>
                        <Form.Item
                          {...answerRestField}
                          label="Is right Answer"
                          name={[answerName, "isRight"]}
                          valuePropName="checked"
                          initialValue={false}
                        >
                          <Switch
                            checked={
                              form.getFieldValue([
                                "questions",
                                name,
                                "answers",
                                answerName,
                                "isRight",
                              ]) || false
                            }
                            onChange={(event: any) => {
                              form.setFieldsValue(form.getFieldsValue());
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col {...{ md: 24, lg: 24 }}>
                        <Form.Item
                          {...answerRestField}
                          label="Content"
                          name={[answerName, "content"]}
                          rules={[
                            {
                              required: true,
                              message: "Please fill the content!",
                            },
                          ]}
                        >
                          <CKEditorApp
                            value={form.getFieldValue([
                              "questions",
                              name,
                              "answers",
                              answerName,
                              "content",
                            ])}
                            onChange={(event: any, editor: any) => {
                              const data = editor.getData();
                              const currentAnswers = form.getFieldValue([
                                "questions",
                                name,
                                "answers",
                              ]);
                              currentAnswers[answerName].content = data;
                              form.setFieldsValue({
                                questions: [
                                  ...form
                                    .getFieldValue("questions")
                                    .slice(0, name),
                                  {
                                    ...form.getFieldValue("questions")[name],
                                    answers: currentAnswers,
                                  },
                                  ...form
                                    .getFieldValue("questions")
                                    .slice(name + 1),
                                ],
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Card>
            )
          )}
          {answerType && answerType === "input" ? (
            ""
          ) : (
            <Form.Item>
              <Button
                type="primary"
                className="mt-15p add-btn"
                onClick={() => {
                  addAnswer(default_answer);

                  const currentAnswers = form.getFieldValue([
                    "questions",
                    name,
                    "answers",
                  ]);
                  dispatch(
                    setDisableSave(currentAnswers.length >= 2 ? false : true)
                  );
                }}
                icon={<PlusOutlined />}
                block
              >
                Add Answer
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
}

export default AddAnswers;
