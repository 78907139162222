import "./index.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import {
  FolderAddFilled,
  FolderFilled,
  ArrowLeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { setNewPractice } from "../../../redux-toolkit";
import { new_practice_form_fields, rightButtons } from "./index.config";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CREATE_PRACTICE } from "../../../redux-toolkit/saga/types";
import PracticeCard from "../../../components/layout/card/PracticeCard";

const componentMap: any = {
  Input: Input,
  DatePicker: DatePicker,
  Switch: Switch,
  Select: Select,
};

const NewPractice: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState<string>("Practice name");
  const [forGroups, setForGroups] = useState<boolean>(false);
  const { newPractice, loaders } = useAppSelector((state) => state.global);

  useEffect(() => {
    if (newPractice?.success) {
      navigate(`/practices/details/?id=${newPractice?.result?.id}`);
      dispatch(setNewPractice(null));
    }
  }, [newPractice, navigate, dispatch]);

  const handleSave = (val: any) => {
    form
      .validateFields()
      .then((values) => {
        if (values.startsAt) {
          values.startsAt = dayjs(values.startsAt).format("YYYY-MM-DD HH:mm");
        }
        if (values.couldStartsAt) {
          values.couldStartsAt = dayjs(values.couldStartsAt).format(
            "YYYY-MM-DD HH:mm"
          );
        }
        dispatch({
          type: CREATE_PRACTICE,
          payload: values,
        });
        console.log("new practice vals:", values);
        form.resetFields();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
  const handleChange = (name: string, value: any) => {
    switch (name) {
      case "name":
        setTitle(value);
        break;
      case "isForGroup":
        setForGroups(value);
        break;
      default:
        break;
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="new-practice"
      onFinish={handleSave}
      className="new-practice-form p-15p"
      initialValues={{
        name: "",
        startsAt: null,
        isActive: false,
        isForGroup: false,
        couldReview: false,
        couldStartsAt: null,
      }}
    >
      <Spin tip="Loading..." spinning={loaders.practiceLoad}>
        <PracticeCard
          title="Add New Practice"
          title_icon={<FolderAddFilled className="title-folder-icon" />}
          right_buttons={
            <>
              <Button
                icon={<ArrowLeftOutlined />}
                type="default"
                className="to-back"
                onClick={() => navigate("/practices")}
              >
                Back
              </Button>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                htmlType="submit"
                className="dyn-submit-btn np-save-btn"
              >
                Save
              </Button>
            </>
          }
          main={{
            left_side: title && (
              <h2 className="new-practice-title">
                <FolderFilled className="practice-folder-icon" />
                {title}
              </h2>
            ),
            right_side: (
              <Row gutter={[32, 16]}>
                {new_practice_form_fields &&
                  new_practice_form_fields?.map((field: any) => {
                    if (field.conditionalRendering && !forGroups) {
                      return null;
                    }
                    const Component = componentMap[field.component];
                    return (
                      <Col key={field.name} md={12} lg={12}>
                        <Form.Item
                          name={field.name}
                          label={field.label}
                          rules={[
                            {
                              required: field.required,
                              type: field.type as any,
                              message: `Please enter the field!`,
                            },
                          ]}
                          valuePropName={field.valuePropName}
                        >
                          <Component
                            {...field.props}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target?.value || e)
                            }
                          />
                        </Form.Item>
                      </Col>
                    );
                  })}
              </Row>
            ),
          }}
        />
      </Spin>
    </Form>
  );
};

export default NewPractice;
