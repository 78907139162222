import {
    ArrowLeftOutlined,
    FolderAddFilled,
    SaveOutlined,
  } from "@ant-design/icons";
  
  export const new_practice_form_fields = [
    {
      name: "name",
      label: "Name",
      type: "string",
      component: "Input",
      required: true,
      props: {
        size: "large",
        onChange: "setTitle",
      },
    },
    {
      name: "startsAt",
      label: "Start Date",
      type: "date",
      component: "DatePicker",
      required: true,
      props: {
        format: "YYYY-MM-DD HH:mm",
        showTime: true,
        showSecond: false,
        size: "large",
        style: { width: "100%" },
      },
    },
    {
      name: "couldStartsAt",
      label: "Could Start Date",
      type: "date",
      component: "DatePicker",
      required: true,
      props: {
        format: "YYYY-MM-DD HH:mm",
        showTime: true,
        showSecond: false,
        size: "large",
        style: { width: "100%" },
      },
    },
    {
      name: "isActive",
      label: "Active",
      type: "boolean",
      component: "Switch",
      required: false,
      valuePropName: "checked",
      props: {
        defaultChecked: false,
      },
    },
    {
      name: "couldReview",
      label: "Could Review",
      type: "boolean",
      component: "Switch",
      required: false,
      valuePropName: "checked",
      props: {
        defaultChecked: false,
      },
    },
    {
      name: "isForGroup",
      label: "For Group",
      type: "boolean",
      component: "Switch",
      required: false,
      valuePropName: "checked",
      props: {
        defaultChecked: false,
        onChange: "setForGroups",
      },
    },
    {
      name: "groupIds",
      label: "Groups",
      type: "array",
      component: "Select",
      required: false,
      props: {
        mode: "multiple",
        allowClear: true,
        style: { width: "100%" },
        placeholder: "Please select",
        options: [
          { label: "101A", value: 101 },
          { label: "101B", value: 201 },
        ],
      },
      conditionalRendering: "forGroups",
    },
  ];
  
  export const rightButtons = (navigate:any) => [
    {
      icon: <ArrowLeftOutlined />,
      label: "Back",
      onClick: () => navigate("/practices"),
      type: "default",
      className:"to-back"
    },
    {
      icon: <SaveOutlined />,
      label: "Save",
      type: "primary",
      className:"dyn-submit-btn np-save-btn",
      htmlType:"submit"
    },
  ];
  