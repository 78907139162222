import "./index.css";
import { useState } from "react";
import Navbar from "./bars/Navbar";
import { Layout, theme } from "antd";
import Sidebar from "./bars/Sidebar";

const { Content } = Layout;
export default function MainLayout(props: ILayoutProps) {
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout>
      <Navbar
        collapsed={collapsed}
        HandleCollapse={() => setCollapsed(!collapsed)}
      />
      <Layout>
        <Sidebar collapsed={collapsed} />
        <Layout>
          <Content
            className="main-layout-content"
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
