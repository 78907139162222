import { Spin } from "antd";
import { setLogged } from "./redux-toolkit";
import { Route, Routes } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import NewPractice from "./pages/practices/new-practice/NewPractice";
import PracticeDetails from "./pages/practices/details/PracticeDetails";

const Users = lazy(() => import("./pages/users/Users"));
const SignIn = lazy(() => import("./pages/signin/SignIn"));
const Practices = lazy(() => import("./pages/practices/Practices"));

function App() {
  window.scrollTo(0, 0);
  const dispatch = useAppDispatch();
  const { isLogged } = useAppSelector((state) => state.global);
  useEffect(() => {
    if (!isLogged && sessionStorage.getItem("auth")) {
      dispatch(setLogged(true));
    }
  }, []);

  return (
    <Suspense
      fallback={<Spin spinning={true} style={{ display: "none" }}></Spin>}
    >
      {isLogged && (
        <MainLayout
          children={
            <>
              <Routes>
                <Route path="/users" element={<Users />} />
                <Route path="/practices" element={<Practices />} />
                <Route path="/new-practice" element={<NewPractice />} />
                {/* <Route path="/ckeditor" element={<CKEditorApp/>} /> */}
                <Route
                  path={"/practices/details/?id=:id?/*"}
                  element={<PracticeDetails />}
                />
                <Route
                  path={"/practices/details/update?id=:id?/*"}
                  element={<PracticeDetails />}
                />
              </Routes>
            </>
          }
        />
      )}
      {!isLogged && (
        <Routes>
          <Route path={"/"} element={<SignIn />} />
          <Route path={"/sign-in"} element={<SignIn />} />
        </Routes>
      )}
    </Suspense>
  );
}

export default App;
