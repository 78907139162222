import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeEvery } from "redux-saga/effects";
import {
  setAnySectionsById,
  setAuth,
  setLoaders,
  setLogged,
  setNewPractice,
  setNewUser,
  setNotification,
  setPractice,
  setPractices,
  setPracticeWithSections,
  setPracticeWithSectionsDublicate,
  setSection,
  setSectionsById,
  setUpdateSection,
  setUsers,
} from "..";
import {
  ILogin,
  IPracticeParams,
  IPracticeResponse,
  IUser,
  IUserParams,
  IUserResponse,
} from "../../services/api/api-services";
import PracticeService from "../../services/api/api-services/practiceService";
import SectionService from "../../services/api/api-services/sectionService";
import UserService from "../../services/api/api-services/userService";
import {
  ANY_SECTIONS,
  CLONE_TO_LEFT,
  CLONE_TO_RIGHT,
  CREATE_PRACTICE,
  CREATE_SECTION,
  CREATE_USER,
  CURRENT_SECTIONS,
  DELETE_PRACTICE,
  DELETE_SECTION,
  DELETE_USER,
  GET_PRACTICE,
  GET_PRACTICES,
  GET_TOKEN,
  GET_USERS,
  UPDATE_PRACTICE,
  UPDATE_SECTION,
  UPDATE_USER,
  WITH_SECTIONS,
} from "./types";

function* _logIn({ payload }: PayloadAction<ILogin>) {
  yield put(setLoaders({ signLoad: true }));
  try {
    const response: IUserResponse = yield UserService.login(payload);

    yield put(setAuth(response?.result?.token));
    yield put(setLoaders({ signLoad: false }));
    yield put(setLogged(true));
    sessionStorage.setItem("auth", response?.result?.token);
  } catch (error: any) {
    yield put(
      setNotification({
        type: "error",
        content: error?.response?.data?.message ?? "Xatolik yuz berdi!",
      })
    );
    yield put(setLoaders({ signLoad: false }));
    yield put(setLogged(false));
    console.log("sign in error => ", error);
  }
}
function* _getUsers({ payload }: PayloadAction<IUserParams>) {
  yield put(setLoaders({ usersLoad: true }));
  try {
    const response: IUserResponse = yield UserService.getUsers(payload);
    yield put(setLoaders({ usersLoad: false }));
    yield put(setUsers(response?.result?.data));
  } catch (error: any) {
    yield put(setLoaders({ usersLoad: false }));
    console.log("get users error => ", error);
  }
}
function* _createUser({ payload }: PayloadAction<IUser>) {
  yield put(setLoaders({ usersLoad: true }));
  try {
    const response: IUserResponse = yield UserService.addUser(payload);
    yield put(setNewUser(response?.result));
    yield put(setLoaders({ usersLoad: false }));
  } catch (error: any) {
    yield put(setLoaders({ usersLoad: false }));
    console.log("create user error => ", error);
  }
}
function* _updateUser({ payload }: PayloadAction<{ id: string; body: IUser }>) {
  yield put(setLoaders({ usersLoad: true }));
  try {
    const response: IUserResponse = yield UserService.update(
      payload.id,
      payload.body
    );
    yield put(setLoaders({ usersLoad: false }));
  } catch (error: any) {
    yield put(setLoaders({ usersLoad: false }));
    console.log("update user error => ", error);
  }
}
function* _deleteUser({ payload }: PayloadAction<{ id: string }>) {
  yield put(setLoaders({ usersLoad: true }));
  try {
    const response: IUserResponse = yield UserService.delete(payload.id);
    yield put(setLoaders({ usersLoad: false }));
  } catch (error: any) {
    yield put(setLoaders({ usersLoad: false }));
    console.log("delete user error => ", error);
  }
}
function* _getPractice({ payload }: PayloadAction<{ id: string }>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield PracticeService.getPractice(
      payload.id
    );
    yield put(setLoaders({ practiceLoad: false }));
    yield put(setPractice(response?.result));
    console.log("get practice => ", response);
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("get practice error => ", error);
  }
}
function* _createPractice({ payload }: PayloadAction<any>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield PracticeService.createPractice(
      payload
    );
    yield put(setLoaders({ practiceLoad: false }));
    yield put(setNewPractice(response));
    console.log("new practice", response);
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("create practice error => ", error);
  }
}
function* _updatePractice({ payload }: PayloadAction<any>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield PracticeService.updatePractice(
      payload
    );

    yield put(setNotification({ type: "success" }));
    yield put(setLoaders({ practiceLoad: false }));
    console.log("update practice", response);
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    yield put(
      setNotification({
        type: "error",
        content: error?.response?.data?.message ?? "Xatolik yuz berdi!",
      })
    );

    console.log("update practice error => ", error);
  }
}
function* _deletePractice({ payload }: PayloadAction<{ id: string }>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield PracticeService.delete(
      payload.id
    );
    yield put(setLoaders({ practiceLoad: false }));
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("delete practice error => ", error);
  }
}
function* _getPractices({ payload }: PayloadAction<IPracticeParams>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield PracticeService.getPractices(
      payload
    );
    yield put(setLoaders({ practiceLoad: false }));
    yield put(setPractices(response?.result?.data));
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("get practices error => ", error);
  }
}
function* _getPracticesWithSections({
  payload,
}: PayloadAction<IPracticeParams>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse =
      yield PracticeService.getPracticesWithSections(payload);
    console.log("get practices witj sections => ", response);
    const structured = response?.result?.data?.map((practice: any) => ({
      _id: practice?._id,
      name: practice?.name,
      sections: practice?.sections,
    }));
    yield put(setLoaders({ practiceLoad: false }));
    yield put(setPracticeWithSections(structured));
    yield put(setPracticeWithSectionsDublicate(structured));
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("get practices error => ", error);
  }
}
function* _createSection({ payload }: PayloadAction<any>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield SectionService.createSection(
      payload
    );
    yield put(setLoaders({ practiceLoad: false }));
    yield put(setSection(response?.result));
    console.log("new section", response);
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("create section error => ", error);
  }
}
function* _cloneToLeftSections({ payload }: PayloadAction<any>) {
  yield put(setLoaders({ transferLoad: true }));
  try {
    console.log("transfer body", payload);

    const response: IPracticeResponse = yield SectionService.postClone(payload);
    const responseW: IPracticeResponse = yield SectionService.getSectionsById(
      payload.toPracticeId
    );
    yield put(setSectionsById(responseW?.result));
    yield put(setLoaders({ transferLoad: false }));
    console.log("clone response", response);
    console.log("current sec response", responseW);
  } catch (error: any) {
    yield put(setLoaders({ transferLoad: false }));
    console.error("clone response error => ", error);
  }
}
function* _cloneToRightSections({ payload }: PayloadAction<any>) {
  yield put(setLoaders({ transferLoad: true }));
  try {
    console.log("transfer body", payload);
    
    const response: IPracticeResponse = yield SectionService.postClone(payload);
    
    const responseP: IPracticeResponse =
    yield PracticeService.getPracticesWithSections({
      search: "",
    });
    console.log("get practices witj sections => ", responseP);
    const structured = responseP?.result?.data?.map((practice: any) => ({
      _id: practice?._id,
      name: practice?.name,
      sections: practice?.sections,
    }));
    yield put(setLoaders({ transferLoad: false }));
    yield put(setPracticeWithSections(structured));
    yield put(setPracticeWithSectionsDublicate(structured));
    yield put(setAnySectionsById(structured?.filter((x:any) => x?._id == payload?.toPracticeId)[0]?.sections));
  } catch (error: any) {
    yield put(setLoaders({ transferLoad: false }));
    console.log("clone to right response error => ", error);
  }
}
function* _updateSection({ payload }: PayloadAction<any>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield SectionService.updateSection(
      payload
    );

    yield put(setUpdateSection(response));
    const responseSec: IPracticeResponse = yield SectionService.getSectionsById(
      String(window.location.href.split("?id=").pop())
    );

    yield put(setSectionsById(responseSec?.result));
    yield put(setLoaders({ practiceLoad: false }));
    // console.log("update section", response);
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    yield put(
      setNotification({
        type: "error",
        content: error?.response?.data?.message ?? "Xatolik yuz berdi!",
      })
    );

    console.log("update section error => ", error);
  }
}
function* _deleteSection({ payload }: PayloadAction<{ id: string }>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield SectionService.deleteSection(
      payload.id
    );
    console.log("delete section response => ", response);
    yield put(setLoaders({ practiceLoad: false }));
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("delete section error => ", error);
  }
}
function* _getSectionsById({ payload }: PayloadAction<{ id: string }>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield SectionService.getSectionsById(
      payload.id
    );
    yield put(setSectionsById(response?.result));
    yield put(setLoaders({ practiceLoad: false }));
    // console.log("current sections", response);
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("current sections error => ", error);
  }
}
function* _getAnySectionsById({ payload }: PayloadAction<{ id: string }>) {
  yield put(setLoaders({ practiceLoad: true }));
  try {
    const response: IPracticeResponse = yield SectionService.getSectionsById(
      payload.id
    );
    yield put(setAnySectionsById(response?.result));
    yield put(setLoaders({ practiceLoad: false }));
  } catch (error: any) {
    yield put(setLoaders({ practiceLoad: false }));
    console.log("any sections by id error => ", error);
  }
}

function* mySaga() {
  yield takeEvery(GET_TOKEN, _logIn);
  yield takeEvery(GET_USERS, _getUsers);
  yield takeEvery(CREATE_USER, _createUser);
  yield takeEvery(UPDATE_USER, _updateUser);
  yield takeEvery(DELETE_USER, _deleteUser);
  yield takeEvery(GET_PRACTICE, _getPractice);
  yield takeEvery(GET_PRACTICES, _getPractices);
  yield takeEvery(WITH_SECTIONS, _getPracticesWithSections);
  yield takeEvery(CREATE_PRACTICE, _createPractice);
  yield takeEvery(UPDATE_PRACTICE, _updatePractice);
  yield takeEvery(DELETE_PRACTICE, _deletePractice);
  yield takeEvery(CREATE_SECTION, _createSection);
  yield takeEvery(UPDATE_SECTION, _updateSection);
  yield takeEvery(DELETE_SECTION, _deleteSection);
  yield takeEvery(CLONE_TO_LEFT, _cloneToLeftSections);
  yield takeEvery(CLONE_TO_RIGHT, _cloneToRightSections);
  yield takeEvery(CURRENT_SECTIONS, _getSectionsById);
  yield takeEvery(ANY_SECTIONS, _getAnySectionsById);
}
export default mySaga;
