import { IPracticeParams, IPracticeResponse } from ".";
import { BaseService } from "../baseService";

class SectionService extends BaseService<any, IPracticeResponse> {
  constructor() {
    super("/sections");
  }
    async getSectionsById(id: string) {
      const response = await this.getById(id, "/sections");
      return response;
    }
    async createSection(data:any) {
      const response = await this.create(data);
      return response;
    }
    async postClone(data: any) {
      const response = await this.post(data, "/sections/clone");
      return response;
    }
    async updateSection(data:any) {
      const response = await this.update(data.id, data.body);
      return response;
    }
    async deleteSection(id:string) {
      const response = await this.delete(id);
      return response;
    }

}

export default new SectionService();
