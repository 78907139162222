export const rightOptions = [
    {
      key: "sa-1",
      label: "Select All",
    },
    {
      key: "is-1",
      label: "Invert Selection",
    },
  ];
  export const leftOptions = [
    {
      key: "sa-2",
      label: "Select All",
    },
    {
      key: "is-2",
      label: "Invert Selection",
    },
  ];