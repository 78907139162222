import "./index.css"
import {
  DeleteOutlined,
  FolderAddFilled,
  HolderOutlined,
  CopyOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import React from "react";
import { Button, Divider, Tooltip } from "antd";
import { useAppDispatch } from "../../../../app/hooks";
import { setSectionsById } from "../../../../redux-toolkit";
import { DELETE_SECTION } from "../../../../redux-toolkit/saga/types";
import PopConfirmApp from "../../../../components/button/PopConfirmApp";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const SectionsTab: React.FC<SectionsTabProps> = ({
  currentSections,
  handleTabClick,
  handleAddSection,
  selectedSectionId,
  practiceUpdate,
  setPracticeUpdate,
}) => {
  const dispatch = useAppDispatch();

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const sections = Array.from(currentSections);
    const [reorderedItem] = sections.splice(result.source.index, 1);
    sections.splice(result.destination.index, 0, reorderedItem);
    dispatch(setSectionsById(sections));
  };

  return (
    <div className="sections-column">
      <Divider orientation="left">Practice Update</Divider>
      <div
        id="details-btn"
        onClick={() => setPracticeUpdate({ details: true, clone: false })}
        className={
          "d-flex-btw d-flex-align section-tab " +
          (practiceUpdate?.details && !practiceUpdate?.clone ? "selected" : "")
        }
      >
        <p>
          <HomeOutlined className="folder-icon" />
          <span>Main Details</span>
        </p>
      </div>
      <div
        onClick={() => setPracticeUpdate({ details: true, clone: true })}
        id="clone-btn"
        className={
          "d-flex-btw d-flex-align section-tab " +
          (practiceUpdate?.details && practiceUpdate?.clone ? "selected" : "")
        }
      >
        <p>
          <CopyOutlined className="folder-icon" />
          <span>Clone</span>
        </p>
      </div>
      <Divider orientation="left" className="mt-5v">
        Sections update
      </Divider>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sections">
          {(provided) => (
            <div
              style={{ width: "100%" }}
              className="sections-tab"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {currentSections &&
                currentSections.map((section, index) => (
                  <Draggable
                    key={section._id}
                    draggableId={section._id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        id={section._id}
                        className={`d-flex-btw section-tab ${
                          selectedSectionId === section._id &&
                          !practiceUpdate.details
                            ? "selected"
                            : ""
                        }`}
                        onClick={handleTabClick}
                      >
                        <p>
                          <HolderOutlined className="folder-icon" />
                          <span>{section.name}</span>
                        </p>
                        <Tooltip title="Delete section">
                          <PopConfirmApp
                            confirm={() => {
                              dispatch({
                                type: DELETE_SECTION,
                                payload: { id: section._id },
                              });
                              dispatch(
                                setSectionsById(
                                  currentSections.filter(
                                    (x) => x._id !== section._id
                                  )
                                )
                              );
                            }}
                            children={
                              <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                danger
                                className="section-delete-icon"
                              />
                            }
                          />
                        </Tooltip>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        icon={<FolderAddFilled />}
        type="primary"
        style={{ width: "100%" }}
        className="mt-20p"
        onClick={handleAddSection}
      >
        Add section
      </Button>
    </div>
  );
};

export default SectionsTab;
