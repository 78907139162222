import { NavigateFunction } from "react-router-dom";
import { UserOutlined , BarChartOutlined, ClusterOutlined  } from "@ant-design/icons";

export const LocalizationData = {
  components: {
    mainLayout: {
      navbar: {
        headTitle: "Admin panel",
      },
      sidebar: {
        menuList: (navigate: NavigateFunction) => [
          {
            key: "1",
            icon: <BarChartOutlined />,
            label: "Dashboard",
            onClick: () => navigate("/"),
          },
          {
            key: "2",
            icon: <UserOutlined />,
            label: "Users",
            onClick: () => navigate("/users"),
          },
          {
            key: "3",
            icon: <ClusterOutlined />,
            label: "Practices",
            onClick: () => navigate("/practices"),
          }
        ],
      },
    },
  },
  pages: {
    users: {},
  },
};
