import Papa from "papaparse";
import { Button, Upload, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";

export default function UploadFileParser(props: IProps) {
  const { HandleAddData } = props;
  const uploadProps: UploadProps = {
    name: "file",
    accept: ".csv",
    className: "upload-question",
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        // console.log("result", e.target);
      };
      // console.log("file", file);
      reader.readAsText(file);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (result: any) {
          const columnArray: any = [];
          const valuesArray: any = [];
          result.data.forEach((d: any) => {
            columnArray.push(...Object.keys(d));
            valuesArray.push(Object.values(d));
          });
          HandleAddData(result.data);
        },
      });

      // Prevent upload
      return false;
    },
    // onChange(info) {
    //   if (info.file.status === "done") {
    //     if (info.file.originFileObj) {
    //       Papa.parse(info.file.originFileObj, {
    //         header: true,
    //         skipEmptyLines: true,
    //         complete: function (result: any) {
    //           const columnArray: string[] = [];
    //           const valuesArray: any[] = [];
    //           result.data.forEach((d: any) => {
    //             columnArray.push(...Object.keys(d));
    //             valuesArray.push(Object.values(d));
    //           });
    //           setData(result.data);
    //           // setColumn(columnArray);
    //           setValues(valuesArray);
    //         },
    //       });
    //     }
    //     message.success(`${info.file.name} file uploaded successfully`);
    //   } else if (info.file.status === "error") {
    //     message.error(`${info.file.name} file upload failed.`);
    //   }
    // },
  };
  return (
    <Upload {...uploadProps}>
      <Button
        type="primary"
        size="large"
        className="full-width"
        icon={<UploadOutlined />}
        ghost
      >
        Upload questions
      </Button>
    </Upload>
  );
}
