import { FormInstance, Input, InputNumber, Select, Switch } from "antd";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setSectionsById } from "../../../redux-toolkit";

export const Fields = (props: { form: FormInstance<any> }) => {
  const { form } = props;
  const dispatch = useAppDispatch();
  const { currentSectionsById, loaders, disableSave } = useAppSelector(
    (state) => state.global
  );
  return [
    {
      name: "name",
      label: "Name",
      type: "string",
      component: (
        <Input
          size="large"
          onChange={(e: any) => {
            let id = form.getFieldsValue()?._id;
            let name = form.getFieldsValue()?.name;
            dispatch(
              setSectionsById(
                currentSectionsById?.map((item) =>
                  item?._id == id ? { ...item, name: name } : item
                )
              )
            );
            // setTitle(e.target.value);
          }}
        />
      ),
      required: true,
    },
    {
      name: "content",
      label: "Content",
      type: "string",
      component: <Input size="large" />,
      required: false,
    },
    {
      name: "duration",
      label: "Duration",
      type: "number",
      component: <InputNumber size="large" style={{ width: "100%" }} />,
      required: true,
    },
    {
      name: "break",
      label: "Break",
      type: "number",
      component: <InputNumber size="large" style={{ width: "100%" }} />,
      required: true,
    },
    {
      name: "type",
      label: "Section type",
      type: "string",
      component: (
        <Select
          size="large"
          style={{ width: "100%" }}
          options={[
            { label: "Math", value: "math" },
            { label: "English", value: "english" },
          ]}
        />
      ),
      required: true,
    },
    {
      name: "shuffle",
      label: "Shuffle",
      type: "boolean",
      component: <Switch />,
      valuePropName: "checked",
      initialValue: false,
      required: false,
    },
    {
      name: "shuffleAsnwers",
      label: "Shuffle Answers",
      type: "boolean",
      component: <Switch />,
      valuePropName: "checked",
      initialValue: false,
      required: false,
    },
  ];
};


