import "./index.css";
import { memo, useEffect, useState } from "react";
import {
  Button,
  Select,
  Checkbox,
  List,
  Row,
  Col,
  Dropdown,
  MenuProps,
  Flex,
  Spin,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  CLONE_TO_LEFT,
  CLONE_TO_RIGHT,
  WITH_SECTIONS,
} from "../../../redux-toolkit/saga/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  setAnySectionsById,
  setPracticeWithSections,
  setTransferLeftSections,
  setTransferSelectedPracticeId,
} from "../../../redux-toolkit";
import PopConfirmApp from "../../button/PopConfirmApp";
import { leftOptions, rightOptions } from "./index.config";

const TransferApp = () => {
  const dispatch = useAppDispatch();
  const {
    loaders,
    anySectionsById,
    updatedPractice,
    currentSectionsById,
    transferLeftSections,
    practicesWithSections,
    transferSelectedPracticeId,
    practicesWithSectionsDublicate,
  } = useAppSelector((state) => state.global);

  useEffect(() => {
    if (practicesWithSections.length === 0) {
      dispatch({
        type: WITH_SECTIONS, // GET_PRACTICES WITH_SECTIONS
        payload: {
          search: "",
        },
      });
    }
  }, []);
  const [selectedItemsLeft, setSelectedItemsLeft] = useState<string[]>([]);
  const [selectedItemsRight, setSelectedItemsRight] = useState<string[]>([]);
  const currentPracticeId = window.location.href.split("?id=").pop();

  const removeDublicate = (arr: any[]) => Array.from(new Set(arr));

  const handleTransferToRight = () => {
    const itemsToTransfer = selectedItemsLeft;
    const newSections = transferLeftSections.filter((section) =>
      itemsToTransfer.includes(section._id)
    );

    if (transferSelectedPracticeId != "") {
      dispatch(
        setPracticeWithSections(
          removeDublicate(
            practicesWithSections.map((x) =>
              x._id == transferSelectedPracticeId
                ? {
                    ...x,
                    sections: [...x.sections, ...newSections],
                  }
                : x
            )
          )
        )
      );
      dispatch(
        setAnySectionsById(
          removeDublicate([...anySectionsById, ...newSections])
        )
      );
    }
    setSelectedItemsLeft([]);
  };
  const handleTransferToLeft = () => {
    const itemsToTransfer = selectedItemsRight;
    dispatch(
      setTransferLeftSections(
        removeDublicate([
          ...transferLeftSections,
          ...anySectionsById.filter((section) =>
            itemsToTransfer.includes(section._id)
          ),
        ])
      )
    );
    setSelectedItemsRight([]);
  };
  const handleSelectAllLeft = () => {
    setSelectedItemsLeft(transferLeftSections.map((section) => section._id));
  };
  const handleSelectAllRight = () => {
    setSelectedItemsRight(anySectionsById.map((section) => section._id));
  };
  const handleInvertSelectionLeft = () => {
    const newSelected = transferLeftSections
      .map((section) => section._id)
      .filter((id) => !selectedItemsLeft.includes(id));
    setSelectedItemsLeft(newSelected);
  };
  const handleInvertSelectionRight = () => {
    const newSelected = anySectionsById
      .map((section) => section._id)
      .filter((id) => !selectedItemsRight.includes(id));
    setSelectedItemsRight(newSelected);
  };
  const handlePracticeChange = (value: string) => {
    practicesWithSections?.filter((x) => {
      if (x?._id == value) {
        return dispatch(setAnySectionsById(x?.sections));
      }
    });
    dispatch(setTransferSelectedPracticeId(value));
  };
  const HandleResetLeft = () =>
    dispatch(setTransferLeftSections(currentSectionsById));
  const HandleResetRight = () => {
    dispatch(
      setPracticeWithSections([
        ...practicesWithSections.map((x: any) =>
          x._id == transferSelectedPracticeId
            ? practicesWithSectionsDublicate.filter((x: any) => {
                if (x._id == transferSelectedPracticeId) {
                  dispatch(setAnySectionsById(x.sections));
                  return x.sections;
                }
              })[0]
            : x
        ),
      ])
    );
  };

  const onMenuClick: MenuProps["onClick"] = (e) =>
    e.key == "sa-1"
      ? handleSelectAllRight()
      : e.key == "sa-2"
      ? handleSelectAllLeft()
      : e.key == "is-2"
      ? handleInvertSelectionLeft()
      : handleInvertSelectionRight();

  const HandleSaveLeft = () => {
    dispatch({
      type: CLONE_TO_LEFT,
      payload: {
        sectionIds: transferLeftSections
          .map((x) => x?._id)
          .filter(
            (x: any) => !currentSectionsById?.map((s) => s?._id).includes(x)
          ),
        toPracticeId: currentPracticeId,
      },
    });
  };
  const HandleSaveRight = () => {
    const prevSections = practicesWithSectionsDublicate
      .filter((x) => x?._id == transferSelectedPracticeId)[0]
      ?.sections?.map((x: any) => x?._id);
    const newSections = anySectionsById
      ?.map((x: any) => x?._id)
      .filter((_id: any) => !prevSections.includes(_id));
    dispatch({
      type: CLONE_TO_RIGHT,
      payload: {
        sectionIds: newSections,
        toPracticeId: transferSelectedPracticeId,
      },
    });
  };
  return (
    <Spin spinning={loaders.transferLoad}>
      <Row gutter={16}>
        <Col sm={24} md={10} lg={10}>
          <Flex justify="space-between" align="center" className="mb-10p">
            <h3>{updatedPractice?.name}</h3>
            <PopConfirmApp
              title="Boshiga qaytish"
              description="Ushbu list dastlabki holatga qaytarilsinmi ?"
              confirm={HandleResetLeft}
              children={
                <Dropdown.Button
                  style={{ width: "auto" }}
                  trigger={["click"]}
                  menu={{ items: leftOptions, onClick: onMenuClick }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRotateRight}
                    className="mr-10p"
                  />
                  Reset
                </Dropdown.Button>
              }
            />
          </Flex>
          <List
            className="transfer-list-box"
            bordered
            dataSource={transferLeftSections}
            renderItem={(item) => (
              <List.Item>
                <Checkbox
                  className={
                    currentSectionsById.filter((s: any) => s._id == item._id)
                      ?.length >= 1
                      ? ""
                      : " transfered-section "
                  }
                  checked={selectedItemsLeft.includes(item._id)}
                  onChange={() => {
                    if (selectedItemsLeft.includes(item._id)) {
                      setSelectedItemsLeft(
                        selectedItemsLeft.filter((id) => id !== item._id)
                      );
                    } else {
                      setSelectedItemsLeft([...selectedItemsLeft, item._id]);
                    }
                  }}
                >
                  {item.name}
                </Checkbox>
              </List.Item>
            )}
          />
          <Button
            type="primary"
            className="dyn-submit-btn transfer-save-btn"
            onClick={HandleSaveLeft}
            disabled={
              currentSectionsById?.length == transferLeftSections?.length
            }
          >
            Save
          </Button>
        </Col>
        <Col sm={24} md={4} lg={4}>
          <div className="transfer-arrow-btns">
            <Button
              onClick={handleTransferToRight}
              className="full-width"
              icon={<FontAwesomeIcon icon={faArrowRightLong} />}
            />
            <Button
              onClick={handleTransferToLeft}
              className="mt-10p full-width"
              icon={<FontAwesomeIcon icon={faArrowLeftLong} />}
            />
          </div>
        </Col>
        <Col sm={24} md={10} lg={10} span={10}>
          <Flex justify="space-between" align="center" className="mb-10p">
            <div>
              <Select
                placeholder="Select practice"
                style={{ minWidth: 100 }}
                defaultValue={
                  practicesWithSections.filter(
                    (practice) => practice._id == transferSelectedPracticeId
                  )[0]?._id
                }
                options={practicesWithSections
                  .filter((practice) => practice?._id != currentPracticeId)
                  .map((practice) => ({
                    label: practice.name,
                    value: practice._id,
                  }))}
                onChange={handlePracticeChange}
              />
            </div>
            <PopConfirmApp
              title="Boshiga qaytish"
              description="Ushbu list dastlabki holatga qaytarilsinmi ?"
              confirm={HandleResetRight}
              children={
                <Dropdown.Button
                  style={{ width: "auto" }}
                  trigger={["click"]}
                  menu={{ items: rightOptions, onClick: onMenuClick }}
                >
                  <FontAwesomeIcon
                    icon={faArrowRotateRight}
                    className="mr-10p"
                  />
                  Reset
                </Dropdown.Button>
              }
            />
          </Flex>
          <List
            className="transfer-list-box"
            bordered
            dataSource={anySectionsById}
            renderItem={(item) => (
              <List.Item>
                <Checkbox
                  className={
                    practicesWithSectionsDublicate
                      .filter((x) => x._id == transferSelectedPracticeId)[0]
                      ?.sections?.map((x: any) => x._id)
                      .includes(item?._id)
                      ? ""
                      : " transfered-section "
                  }
                  checked={selectedItemsRight.includes(item._id)}
                  onChange={() => {
                    if (selectedItemsRight.includes(item._id)) {
                      setSelectedItemsRight(
                        selectedItemsRight.filter((id) => id !== item._id)
                      );
                    } else {
                      setSelectedItemsRight([...selectedItemsRight, item._id]);
                    }
                  }}
                >
                  {item.name}
                </Checkbox>
              </List.Item>
            )}
          />
          <Button
            type="primary"
            className="dyn-submit-btn transfer-save-btn"
            onClick={HandleSaveRight}
            disabled={
              anySectionsById?.length ==
                practicesWithSectionsDublicate.filter(
                  (x) => x._id == transferSelectedPracticeId
                )[0]?.sections?.length || anySectionsById.length == 0
            }
          >
            Save
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default memo(TransferApp);
