export const question_type = [
  {
    label: "Option",
    value: "option",
  },
  {
    label: "Input",
    value: "input",
  },
];
export const column_type = [
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Double",
    value: "double",
  },
];
