export const GET_USERS = "GET_USERS";
export const GET_TOKEN = "GET_TOKEN";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_PRACTICE = "GET_PRACTICE";
export const GET_PRACTICES = "GET_PRACTICES";
export const CREATE_PRACTICE = "CREATE_PRACTICE";
export const UPDATE_PRACTICE = "UPDATE_PRACTICE";
export const DELETE_PRACTICE = "DELETE_PRACTICE";
export const CREATE_SECTION = "CREATE_SECTION";
export const CURRENT_SECTIONS = "CURRENT_SECTIONS";
export const WITH_SECTIONS = "WITH_SECTIONS";
export const ANY_SECTIONS = "ANY_SECTIONS";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const DELETE_SECTION = "DELETE_SECTION";
export const CLONE_TO_LEFT = "CLONE_TO_LEFT";
export const CLONE_TO_RIGHT = "CLONE_TO_RIGHT";
